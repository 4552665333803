import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, HostListener, inject, OnInit, Output, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatFormField } from '@angular/material/form-field';
import { MatInput, MatLabel } from '@angular/material/input';
import { MatButton } from '@angular/material/button';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { fab } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
  standalone: true,
  imports: [CommonModule, MatButton, ReactiveFormsModule, MatLabel, MatFormField, MatInput, FontAwesomeModule]
})
export class LandingComponent implements OnInit {
  private afs: AngularFirestore = inject(AngularFirestore);
  private library: FaIconLibrary = inject(FaIconLibrary);
  private fb: FormBuilder = inject(FormBuilder);
  contactForm: FormGroup = this.fb.group({
    name: ['', Validators.required],
    email: ['', Validators.required],
    message: ['', Validators.required],
  });
  formMessage: string = "";
  sendingEmail: boolean = false;
  today: Date = new Date();
  shows: any[] = [];
  activeNavClass: string = "home";
  scrollClass: boolean = false;

  constructor() {
    this.library.addIconPacks(fas, fab);
  }

  ngOnInit(): void {
    this.afs.collection("shows", ref=>ref.where("date_timestamp", ">=", this.today)).get().subscribe(showsCollection => {
      showsCollection.docs.forEach(show => {
        this.shows.push(show.data());
      });
    });
  }

  @HostListener('window:scroll', ['$event'])

  onScroll(event: any) {
    var homeSection = document.getElementById("home");
    var aboutSection = document.getElementById("about");
    var socialSection = document.getElementById("social");
    var showsSection = document.getElementById("shows");
    var contactSection = document.getElementById("contact");

    if(this.checkIfElementIsWithinViewport(homeSection)) {
      this.activeNavClass = "home";
    } else if(this.checkIfElementIsWithinViewport(aboutSection)) {
      this.activeNavClass = "about";
    } else if(this.checkIfElementIsWithinViewport(socialSection)) {
      this.activeNavClass = "social";
    } else if(this.checkIfElementIsWithinViewport(showsSection)) {
      this.activeNavClass = "shows";
    } else if(this.checkIfElementIsWithinViewport(contactSection)) {
      this.activeNavClass = "contact";
    }

    if(window.pageYOffset >= 100) {
      this.scrollClass = true;
    } else {
      this.scrollClass = false;
    }
  }

  scrollToElement(targetRef: HTMLElement) {
    const elementPosition = targetRef.getBoundingClientRect().top + window.scrollY;
    const offsetPosition = elementPosition - 150;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    });
  }

  checkIfElementIsWithinViewport(element: any) {
    if((element != undefined) && (element != null)) {
        var element_offets = element.getBoundingClientRect();
        return (
            (element_offets.top >= 0) &&
            (element_offets.left >= 0) &&
            (element_offets.bottom <= window.innerHeight) &&
            (element_offets.right <= window.innerWidth)
        );
    } else {
        return false;
    }
  }

  submitContactForm() {
    if(!this.contactForm.valid) {
      console.log("Error", this.contactForm.value);
      return;
    }

    this.sendingEmail = true;
    this.formMessage = "";

    this.afs.collection("mail").add({
      to: 'josh@thecomedystand.com',
      message: {
        subject: 'New Website Message',
        text: `THERE'S BEEN A NEW MESSAGE FROM YOUR PERSONAL WEBSITE:\r\n\r\n
          ------------------\r\n\r\n

          Name:\r\n
          ${this.contactForm.value.name}\r\n\r\n

          Email:\r\n
          ${this.contactForm.value.email}\r\n\r\n
          
          Message:\r\n
          ${this.contactForm.value.message}`,
        html: `<h2>New Message From Your Personal Website</h2>
        <p><strong>Name:</strong><br/>${this.contactForm.value.name}</p>
        <p><strong>Email:</strong><br/>${this.contactForm.value.email}</p>
          <p><strong>Message:</strong><br/>${this.contactForm.value.message}`
      }
    }).catch(error => {
      console.log("Error", error);
      this.sendingEmail = false;
    }).then((data) => {
      this.sendReceiptEmail();
    });
  }

  sendReceiptEmail() {
    this.afs.collection("mail").add({
      to: this.contactForm.value.email,
      message: {
        subject: 'I\'ve Received Your Message',
        text: `Hi ${this.contactForm.value.name}, I have received your message and will respond as soon as I can. Thanks!`,
        html: `<p>Hi ${this.contactForm.value.name}</p>
          <p>I have received your message, and will respond as soon as I can. Thanks for reaching out!</p>
          <p>Josh Poulain</p>`
      }
    }).catch(error => {
      console.log("Error", error);
      this.sendingEmail = false;
    }).then((data) => {
      this.sendingEmail = false;
      this.formMessage = "Thanks! I've received your message and will respond ASAP."
      this.contactForm.reset();
    })
  }

}
