// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyDcJCVvPkFsivo_PYd41k_-VeqGKlLE5aQ",
    authDomain: "joshpoulain-e1081.firebaseapp.com",
    projectId: "joshpoulain-e1081",
    storageBucket: "joshpoulain-e1081.appspot.com",
    messagingSenderId: "15021484327",
    appId: "1:15021484327:web:a6186629e28edaaf74a102",
    measurementId: "G-R64HM2E4KH"
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
