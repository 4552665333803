<header class="main-header" [ngClass]="{'scrolling': scrollClass}">
    <div class="container">
        <div class="logo">
            Josh.
        </div>
        <nav>
            <ul>
                <li>
                    <a href="javascript:void(0)" [ngClass]="{'active': activeNavClass === 'home'}" (click)="scrollToElement(homeSection)">Home</a>
                </li>
                <li>
                    <a href="javascript:void(0)" [ngClass]="{'active': activeNavClass === 'about'}" (click)="scrollToElement(aboutSection)">About</a>
                </li>
                <li>
                    <a href="javascript:void(0)" [ngClass]="{'active': activeNavClass === 'shows'}" (click)="scrollToElement(showsSection)">Shows</a>
                </li>
                <li>
                    <a href="javascript:void(0)" [ngClass]="{'active': activeNavClass === 'social'}" (click)="scrollToElement(socialSection)">Social</a>
                </li>
                <li>
                    <a href="javascript:void(0)" [ngClass]="{'active': activeNavClass === 'contact'}" (click)="scrollToElement(contactSection)">Contact</a>
                </li>
            </ul>
        </nav>
    </div>
</header>

<section class="landing">
    <section id="home" class="hero" #homeSection>
        <div class="content">
            <h1>Josh Poulain.</h1>

            <h3>
                <span>Comedian</span>
                <span>Writer</span>
                <span>Host</span>
            </h3>
        </div>
    </section>

    <section id="about" class="page-section" #aboutSection>
        <div class="container">
            <h2>Bio</h2>

            <div class="flex">
                <div class="flex-2">
                    <p>
                        Josh Poulain is a talented comedian known for his sharp, relatable humor and captivating performances. As a finalist in the East Coast Roast Battle and a performer at the Hubcap Comedy Festival, Josh has earned a reputation for his quick wit and comedic versatility.
                        His career has taken him across Canada and Europe, where he has delighted audiences with his unique brand of humor.
                    </p>
        
                    <p>
                        In addition to his stand-up success, Josh is a key figure in the Maritimes' comedy scene. He is the co-founder of
                        The Comedy Stand, the region's premier comedy event producer, and a part-owner of the Halifax Live Comedy Club, where
                        he continues to contribute to the vibrant local comedy community. Josh's passion for comedy and his
                        entrepreneurial spirit have made him a driving force in the industry, both on stage and behind the scenes.
                    </p>
                </div>

                <div class="flex-1 center">
                    <img src="assets/images/headshot.jpeg" class="profile-pic" alt="" />
                </div>
            </div>
        </div>
    </section>

    <div class="page-section">
        <div class="credits">
            <div class="container">
                <img src="assets/images/comedystand.png" alt="" />
                <img src="assets/images/halifaxlive.png" alt="" />
                <img src="assets/images/hubcap.png" alt="" />
            </div>
        </div>
    </div>

    <section id="shows" class="page-section" #showsSection>
        <div class="container">
            <h2>Upcoming Shows</h2>
            <ul class="grid two-cols">
                @for(show of shows; track show) {
                    <li>
                        <span class="calendar">
                            <span class="date">
                                {{ show.date_timestamp.seconds * 1000 | date: 'MMM dd' }}
                            </span>
                            <span class="time">
                                {{ show.date_timestamp.seconds * 1000 | date: 'h:mm a' }}
                            </span>
                        </span>
                        
                        <div class="show-details">
                            <h3>{{ show.title }}</h3>

                            <p>
                                at {{ show.venue }}
                                @if(show.ticket_link) {
                                    |
                                    <a href="{{ show.ticket_link }}" target="_blank">
                                        Get Tickets
                                        <fa-icon [icon]="['fas', 'chevron-right']"></fa-icon>
                                    </a>
                                }
                            </p>
                        </div>
                    </li>
                } @empty {
                    <li>
                        <p>
                            No upcoming shows found.
                        </p>
                    </li>
                }
            </ul>
        </div>
    </section>

    <section id="social" class="page-section" #socialSection>
        <div class="container">
            <h2>Social</h2>

            <ul>
                <li>
                  <a href="https://instagram.com/hiphiparray" target="_blank">
                    <fa-icon [icon]="['fab', 'instagram']"></fa-icon>
                  </a>
                </li>
                <li>
                  <a href="https://youtube.com/@joshpoulaincomedy" target="_blank">
                    <fa-icon [icon]="['fab', 'youtube']"></fa-icon>
                  </a>
                </li>
                <li>
                  <a href="https://instagram.com/hiphiparray" target="_blank">
                    <fa-icon [icon]="['fab', 'tiktok']"></fa-icon>
                  </a>
                </li>
                <li>
                  <a href="https://threads.net/hiphiparray" target="_blank">
                    <fa-icon [icon]="['fab', 'threads']"></fa-icon>
                  </a>
                </li>
              </ul>
        </div>
    </section>

    <section id="contact" class="page-section" #contactSection>
        <div class="container">
            <h2>Say Hello</h2>

            <p>
                Do you have more questions? Want to book a show or collaborate on another project? Reach out below!
            </p>

            <form [formGroup]="contactForm" (ngSubmit)="submitContactForm()">
                <div class="grid two-cols">
                    <mat-form-field appearance="outline">
                        <mat-label>Name</mat-label>
                        <input type="text" formControlName="name" matInput />
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Email</mat-label>
                        <input type="email" formControlName="email" matInput />
                    </mat-form-field>
                </div>

                <mat-form-field appearance="outline">
                    <mat-label>Message</mat-label>
                    <textarea matInput formControlName="message"></textarea>
                </mat-form-field>

                <p>
                    <button type="submit" mat-flat-button [disabled]="sendingEmail">
                        Send Email
                    </button>
                </p>

                <p class="form-message">
                    {{ formMessage }}
                </p>
            </form>
        </div>
    </section>
</section>

<footer class="main-footer">
    <div class="container">
        <ul class="grid three-cols">
            <li>
                <fa-icon [icon]="['fas', 'coffee']"></fa-icon>
            </li>
            <li>
                <fa-icon [icon]="['fas', 'microphone']"></fa-icon>
            </li>
            <li>
                <fa-icon [icon]="['fas', 'beer']"></fa-icon>
            </li>
        </ul>
    </div>
</footer>

<footer class="sub-footer">
    Copyright &copy; {{ today.getFullYear() }} Josh Poulain. All Rights Reserved.
</footer>